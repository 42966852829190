import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, mergeMap, firstValueFrom, from, Observable, of } from 'rxjs';
import { Auth, signInWithEmailAndPassword, signOut, authState, User, sendPasswordResetEmail } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private auth: Auth = inject(Auth);
	private functions: Functions = inject(Functions);


	isLoggedIn$: Observable<boolean>;
	isAdmin$: Observable<boolean>;
	isTrainer$: Observable<boolean>;
	isCustomer$: Observable<boolean>;

	currentAuthUser$: Observable<User | null>;

	constructor(
		private router: Router,
	) {
		this.initStatesAndRoles();
	}

	async login(email: string, password: string) {
		return signInWithEmailAndPassword(this.auth, email.trim(), password.trim());
	}

	async logout() {
		return signOut(this.auth)
			.then(() => this.router.navigate(['/login']))
			.catch(err => console.error(err))
	}

	private initStatesAndRoles() {
		this.currentAuthUser$ = authState(this.auth);
		this.isLoggedIn$ = this.currentAuthUser$.pipe(map(user => !!user));
		this.isAdmin$ = this.isRole('admin');
		this.isTrainer$ = this.isRole('trainer');
		this.isCustomer$ = this.isRole('customer');
	}

	private isRole(role: 'admin' | 'trainer' | 'customer') {
		return this.currentAuthUser$.pipe(mergeMap(user => user ? from(user.getIdTokenResult()) : of(null)))
			.pipe(map(idTokenResult => idTokenResult?.claims?.[role] === true));
	}

	async getCurentUserId() {
		const currentUserId$ = this.currentAuthUser$.pipe(map(user => user?.uid));
		return firstValueFrom(currentUserId$);
	}

	resetEmail(email: string) {
		return sendPasswordResetEmail(this.auth, email);
	}

	updateAuthUserEmail(uid: string, email: string) {
		const fnUpdateAuthUserEmail = httpsCallable(this.functions, 'updateAuthUserEmail');
		return fnUpdateAuthUserEmail({ uid, email });
	}

	deleteAuthUser(uid: string) {
		const fnDeleteAuthUser = httpsCallable(this.functions, 'deleteAuthUser');
		return fnDeleteAuthUser({ uid });
	}

}
