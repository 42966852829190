import { Pipe, PipeTransform } from '@angular/core';
import { Challenge } from '../models/challenge';

@Pipe({
	name: 'filterChallenges'
})
export class FilterChallengesPipe implements PipeTransform {

	transform(challenges: Challenge[] | null, date: Date | null): Challenge[] | null {
		if (!challenges) {
			return challenges;
		}
		if (date) {
			return challenges.filter(challenge => challenge.scheduledAt?.toDate().toDateString() === date.toDateString());
		} else {
			return challenges.filter(challenge => !challenge.scheduledAt && !challenge.finishedAt);
		}
	}

}
