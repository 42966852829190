import { Pipe, PipeTransform } from '@angular/core';
import { TrainerService } from './../../core/services/trainer.service';
import { Trainer } from '../models/trainer';
import { DocumentData, DocumentReference } from '@angular/fire/firestore';
import { Observable, take } from 'rxjs';

@Pipe({
	name: 'getTrainer',
})
export class GetTrainerPipe implements PipeTransform {

	constructor(private trainerSvc: TrainerService) { }

	transform(ref: DocumentReference<DocumentData>, ...args: unknown[]): Observable<Trainer> | null {
		if (ref) {
			return this.trainerSvc.getById(ref.id).pipe(take(1));
		}
		return null;
	}

}
