import { Injectable, Injector } from '@angular/core';
import { ConsumedMeal } from 'src/app/shared/models/consumed-meal';
import { FirestoreService } from './firestore.service';
import { DocumentReference, DocumentData, collection, collectionSnapshots, query, where } from '@angular/fire/firestore';
import { map } from 'rxjs';
import { convertSnaps } from '../utils/db-utils';
import { TotalNutrition } from 'src/app/shared/models/total-nutrition';

export const CONSUSMED_MEALS_PATH: string = 'consumedMeals';

@Injectable({
	providedIn: 'root'
})
export class ConsumedMealService extends FirestoreService<ConsumedMeal> {

	constructor(injector: Injector) {
		super(injector, CONSUSMED_MEALS_PATH);
	}

	getConsumedMealsForDate(customerRef: DocumentReference<DocumentData>, date: Date) {
		const endDate = new Date(date);
		endDate.setDate(endDate.getDate() + 1);
		const consumedMealsRef = collection(this.firestore, customerRef.path, CONSUSMED_MEALS_PATH);
		return collectionSnapshots(query(consumedMealsRef, where('consumedAt', '>=', new Date(date.toDateString())), where('consumedAt', '<', new Date(endDate.toDateString()))))
			.pipe(
				map(snaps => convertSnaps<ConsumedMeal>(snaps)),
			);
	}

	getConsumedNutritionValues(consumedMeals: ConsumedMeal[] = []) {
		const totalNutrition: TotalNutrition = {
			carbohydrates: {
				uom: 'g',
				value: 0,
			},
			energyKcal: {
				uom: 'kcal',
				value: 0,
			},
			energyKj: {
				uom: 'kJ',
				value: 0,
			},
			fat: {
				uom: 'g',
				value: 0,
			},
			proteins: {
				uom: 'g',
				value: 0,
			}
		}

		return consumedMeals.reduce((totalNutrition, consumedMeal) => {
			/* totalNutrition.carbohydrates.value += consumedMeal.kcalAdjustment * consumedMeal.recipe.totalNutrition.carbohydrates.value;
			totalNutrition.energyKcal.value += consumedMeal.kcalAdjustment * consumedMeal.recipe.totalNutrition.energyKcal.value;
			totalNutrition.energyKj.value += consumedMeal.kcalAdjustment * consumedMeal.recipe.totalNutrition.energyKj.value;
			totalNutrition.fat.value += consumedMeal.kcalAdjustment * consumedMeal.recipe.totalNutrition.fat.value;
			totalNutrition.proteins.value += consumedMeal.kcalAdjustment * consumedMeal.recipe.totalNutrition.proteins.value; */

			/* totalNutrition.carbohydrates.value += (consumedMeal.mealRef ? consumedMeal.kcalAdjustment : 1) * consumedMeal.recipe.totalNutrition.carbohydrates.value;
			totalNutrition.energyKcal.value += (consumedMeal.mealRef ? consumedMeal.kcalAdjustment : 1) * consumedMeal.recipe.totalNutrition.energyKcal.value;
			totalNutrition.energyKj.value += (consumedMeal.mealRef ? consumedMeal.kcalAdjustment : 1) * consumedMeal.recipe.totalNutrition.energyKj.value;
			totalNutrition.fat.value += (consumedMeal.mealRef ? consumedMeal.kcalAdjustment : 1) * consumedMeal.recipe.totalNutrition.fat.value;
			totalNutrition.proteins.value += (consumedMeal.mealRef ? consumedMeal.kcalAdjustment : 1) * consumedMeal.recipe.totalNutrition.proteins.value; */

			totalNutrition.carbohydrates.value += consumedMeal.recipe.totalNutrition.carbohydrates.value;
			totalNutrition.energyKcal.value += consumedMeal.recipe.totalNutrition.energyKcal.value;
			totalNutrition.energyKj.value += consumedMeal.recipe.totalNutrition.energyKj.value;
			totalNutrition.fat.value += consumedMeal.recipe.totalNutrition.fat.value;
			totalNutrition.proteins.value += consumedMeal.recipe.totalNutrition.proteins.value;

			return totalNutrition;
		}, totalNutrition);
	}

}
