import { Pipe, PipeTransform } from '@angular/core';
import { NutritionPlanService } from 'src/app/core/services/nutrition-plan.service';
import { Customer } from '../models/customer';
import { Observable } from 'rxjs';

@Pipe({
	name: 'nutritionPlanCount'
})
export class NutritionPlanCountPipe implements PipeTransform {

	constructor(private nutritionPlanSvc: NutritionPlanService) { }

	transform(customer: Customer): Observable<number | null> {
		return this.nutritionPlanSvc.getRemainingNutritionPlanDays(customer.ref);
	}
}
