import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';

@Pipe({
	name: 'user'
})
export class UserPipe implements PipeTransform {

	transform(user?: User | null, value: 'fullName' | 'role' = 'fullName'): string {
		if (!user) {
			return '-';
		}

		switch (value) {
			case 'fullName': {
				return `${user.firstName} ${user.lastName}`.trim() || '-';
			}
			case 'role': {
				return user.role || '-';
			}
			default: {
				return '-';
			}
		}
	}

}
