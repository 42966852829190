import { Pipe, PipeTransform } from '@angular/core';
import { FormElementType } from '../models/form-element';

@Pipe({
  name: 'formElementType'
})
export class FormElementTypePipe implements PipeTransform {

  transform(type: FormElementType, display: 'text' | 'icon' = 'text'): string {
    if (display === 'text') {
      return this.getText(type);
    } else {
      return this.getIcon(type);
    }
  }

  getText(type: FormElementType) {
    switch (type) {
      case FormElementType.MULTIPLE_CHOICE:
        return 'Mehrfachauswahl';
      case FormElementType.NUMBER:
        return 'Zahlenwert';
      case FormElementType.SINGLE_CHOICE:
        return 'Einzelauswahl';
      case FormElementType.TEXT:
        return 'Freitext';
    }
  }

  getIcon(type: FormElementType) {
    switch (type) {
      case FormElementType.MULTIPLE_CHOICE:
        return 'check_box';
      case FormElementType.NUMBER:
        return '123';
      case FormElementType.SINGLE_CHOICE:
        return 'radio_button_checked';
      case FormElementType.TEXT:
        return 'edit_note';
    }
  }

}
