import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PasswordResetDialogComponent } from '../../../../shared/components/password-reset-dialog/password-reset-dialog.component';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { AuthService } from '../../auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginForm = new UntypedFormGroup({
		email: new UntypedFormControl('', [Validators.required, Validators.email]),
		password: new UntypedFormControl('', Validators.required),
	});

	constructor(
		private authSvc: AuthService,
		private router: Router,
		private snackbarSvc: SnackbarService,
		private dialog: MatDialog,
	) { }

	ngOnInit(): void {
		this.authSvc.currentAuthUser$.subscribe(authUser => {
			authUser?.getIdTokenResult().then((idTokenResult) => {
				// Role Admin
				if (idTokenResult.claims.admin === true) {
					this.router.navigate(['/trainers']);
				}
				// Role Trainer
				else if (idTokenResult.claims.trainer === true) {
					this.router.navigate(['/customers/list']);
				}
				// Role customer or unknown
				else {
					this.authSvc.logout();
					this.snackbarSvc.showError('Kein Zugang zum Admin Bereich - ' + (idTokenResult.claims.customer === true ? 'role: customer' : 'role: unknown'));
				}
			})
				.catch((error) => {
					console.log(error);
				})
		});
	}

	onLogin() {
		this.authSvc.login(this.loginForm.value.email, this.loginForm.value.password)
			.catch(err => {
				console.error(err)
				this.snackbarSvc.showError("Login Error: " + err);
			});
	}

	onForgotPasswort() {
		const email = this.loginForm.value.email;
		this.dialog.open(PasswordResetDialogComponent, {
			data: { email },
			disableClose: true,
			width: '384px'
		})
	}

}
