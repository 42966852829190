import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './auth/pages/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { MainNavComponent } from './main-nav/main-nav.component';


@NgModule({
	declarations: [
		// Components
		LoginComponent,
		PageNotFoundComponent,
		MainNavComponent,
	],
	imports: [
		// Routing
		RouterModule,

		// Angular
		CommonModule,

		// Shared
		SharedModule,
	],
	exports: [
		// Components
		LoginComponent,
		MainNavComponent,
	]
})
export class CoreModule { }
