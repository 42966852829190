export const getDaysDifference = (date1: Date, date2: Date) => {
	// Convert both dates to milliseconds
	const date1Time = date1.getTime();
	const date2Time = date2.getTime();

	// Calculate the difference in milliseconds
	const timeDifference = date2Time - date1Time;

	// Convert the difference from milliseconds to days
	const daysDifference = timeDifference / (1000 * 3600 * 24);

	// Return the difference in days
	return Math.abs(Math.ceil(daysDifference)); // Absolute value and rounding to handle potential timezone differences
}

export const getToday = () => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return today;
}