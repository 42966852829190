import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { Address } from '../../../shared/models/address';
import * as geofire from 'geofire-common';

declare var google: any;

@Component({
	selector: 'app-places-autocomplete',
	templateUrl: './places-autocomplete.component.html',
	styleUrls: ['./places-autocomplete.component.scss']
})
export class PlacesAutocompleteComponent implements OnInit, AfterViewInit {

	@Output() addressChange: EventEmitter<Address> = new EventEmitter();
	@ViewChild('addressText') addressRef: ElementRef;

	addressFormGroup: UntypedFormGroup;

	constructor(private _controlContainer: ControlContainer) { }

	ngOnInit() {
		this.addressFormGroup = this._controlContainer.control as UntypedFormGroup;
	}

	ngAfterViewInit() {
		this.initAutocomplete();
	}

	initAutocomplete() {
		const input = this.addressRef.nativeElement;
		const autocomplete = new google.maps.places.Autocomplete(input, {
			componentRestrictions: { country: 'DE' },
			types: ['address']  // 'establishment' / 'address' / 'geocode'
		});

		google.maps.event.addListener(autocomplete, 'place_changed', () => {

			const place = autocomplete.getPlace();
			const formattedAddress = place.formatted_address;

			const street = place.address_components.find((addressComponent: any) => addressComponent.types.includes('route'));
			const houseNumber = place.address_components.find((addressComponent: any) => addressComponent.types.includes('street_number'));
			const zip = place.address_components.find((addressComponent: any) => addressComponent.types.includes('postal_code'));
			const city = place.address_components.find((addressComponent: any) => addressComponent.types.includes('locality'));
			const country = place.address_components.find((addressComponent: any) => addressComponent.types.includes('country'));
			const lat = place.geometry.location.lat();
			const lng = place.geometry.location.lng();
			const hash = geofire.geohashForLocation([lat, lng]);

			let address: Address = {
				formattedAddress: formattedAddress || null,
				street: street && street.long_name || null,
				houseNumber: houseNumber && houseNumber.long_name || null,
				zip: zip && zip.long_name || null,
				city: city && city.long_name || null,
				country: country && country.long_name || null,
				location: {
					geohash: hash,
					lat: lat,
					lng: lng
				}
			};

			this.addressFormGroup.patchValue(address);
			this.emitAddress(address);
		});
	}

	emitAddress(address: Address) {
		this.addressChange.emit(address);
	}

}
