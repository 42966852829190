import { Pipe, PipeTransform } from '@angular/core';
import { FormElement } from '../models/form-element';

@Pipe({
  name: 'filterFormElements'
})
export class FilterFormElementsPipe implements PipeTransform {

  transform(formElements?: FormElement[] | null, searchTerm?: string | null): FormElement[] {
    if (!formElements || !searchTerm) {
      return formElements ?? [];
    }
    return formElements.filter(formElement => {
      return formElement.questionTitle.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || formElement.questionText.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
    }).sort((a, b) => {
      // Check if searchTerm is found in the questionTitle for each element
      const foundInTitleA = a.questionTitle.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
      const foundInTitleB = b.questionTitle.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());

      // Sort elements where searchTerm is found in the title first
      if (foundInTitleA && !foundInTitleB) {
        return -1; // a comes before b
      } else if (!foundInTitleA && foundInTitleB) {
        return 1;  // b comes before a
      } else {
        return 0;  // no change in order if both are the same
      }
    })
  }

}
