import { Router } from '@angular/router';
import { UntypedFormArray } from '@angular/forms';


export function pageNotFoundRedirect(router: Router) {
	return router.navigate(['/404']);
}

/**
 * Moves an item in a FormArray to another position.
 * @param formArray FormArray instance in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which he item should be moved.
 */
export function moveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
	const dir = toIndex > fromIndex ? 1 : -1;

	const from = fromIndex;
	const to = toIndex;

	const temp = formArray.at(from);
	for (let i = from; i * dir < to * dir; i = i + dir) {
		const current = formArray.at(i + dir);
		formArray.setControl(i, current);
	}
	formArray.setControl(to, temp);
}

export function getSubstringUntilLastSlash(s: string) {
	const lastIndex = s.lastIndexOf('/');
	if (lastIndex !== -1) {
		return s.substring(0, lastIndex);
	} else {
		return s;
	}
}