import { Injectable, Injector } from '@angular/core';
import { User } from '../../shared/models/user';
import { FirestoreService } from './firestore.service';
import { AuthService } from '../auth/auth.service';
import { Observable, of, mergeMap } from 'rxjs';

export const USER_PATH: string = 'users';

@Injectable({
	providedIn: 'root'
})
export class UserService extends FirestoreService<User> {

	currentUser$: Observable<User | null>;

	constructor(
		injector: Injector,
		private authSvc: AuthService,
	) {
		super(injector, USER_PATH);
		this.subscribeCurrentAuthUser();
	}

	private subscribeCurrentAuthUser() {
		this.currentUser$ = this.authSvc.currentAuthUser$.pipe(mergeMap(user => user ? this.getById(user.uid) : of(null)));
	}

}
