import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, shareReplay } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { TrainerService } from '../services/trainer.service';

@Component({
	selector: 'app-main-nav',
	templateUrl: './main-nav.component.html',
	styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {

	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map(result => result.matches),
			shareReplay()
		);

	trainerPath?: string;

	constructor(
		private breakpointObserver: BreakpointObserver,
		public authSvc: AuthService,
		public userSvc: UserService,
		public trainerSvc: TrainerService,
	) { }

	ngOnInit(): void {
		this.userSvc.currentUser$.subscribe(user => {
			if (user && user.role === 'trainer') {
				this.trainerPath = this.trainerSvc.getRef(user.ref.id).path;
			}
		});
	}

	onLogout() {
		this.authSvc.logout();
	}

}
