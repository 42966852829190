import { Pipe, PipeTransform } from '@angular/core';
import { DeviceRecordService } from 'src/app/core/services/device-record.service';
import { Customer } from '../models/customer';
import { Observable, of } from 'rxjs';

@Pipe({
	name: 'deviceRecordCount'
})
export class DeviceRecordCountPipe implements PipeTransform {

	constructor(private deviceRecordSvc: DeviceRecordService) { }

	transform(customer: Customer, type: 'lastLoginDayCount' = 'lastLoginDayCount'): Observable<number | null> {
		if (type === 'lastLoginDayCount') {
			return this.deviceRecordSvc.getLastLoginDayCount(customer.ref);
		}
		return of(null);
	}

}
