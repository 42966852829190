import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Recipe } from '../../models/recipe';

@Component({
	selector: 'app-recipe-preview-dialog',
	templateUrl: './recipe-preview-dialog.component.html',
	styleUrls: ['./recipe-preview-dialog.component.scss']
})
export class RecipePreviewDialogComponent implements OnInit {

	recipe: Recipe;

	constructor(@Inject(MAT_DIALOG_DATA) data: { recipe: Recipe }) {
		this.recipe = data.recipe;
	}

	ngOnInit(): void { }

}
