import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/auth/pages/login/login.component';
import { PageNotFoundComponent } from './core/error/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'trainers',
    loadChildren: () => import('./modules/trainer/trainer.module').then(m => m.TrainerModule)
  },
  {
    path: 'licenses',
    loadChildren: () => import('./modules/license/license.module').then(m => m.LicenseModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('./modules/request/request.module').then(m => m.RequestModule)
  },
  {
    path: 'tags',
    loadChildren: () => import('./modules/tag/tag.module').then(m => m.TagModule)
  },
  {
    path: 'exercises',
    loadChildren: () => import('./modules/exercise/exercise.module').then(m => m.ExerciseModule)
  },
  {
    path: 'challenges',
    loadChildren: () => import('./modules/challenge/challenge.module').then(m => m.ChallengeModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'recipes',
    loadChildren: () => import('./modules/recipe/recipe.module').then(m => m.RecipeModule)
  },
  {
    path: 'nutrition-plans',
    loadChildren: () => import('./modules/nutrition-plan/nutrition-plan.module').then(m => m.NutritionPlanModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./modules/form/form.module').then(m => m.FormModule)
  },
  { path: 'login', component: LoginComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
