<div class="energy-nutrition-value-row" [class.horizontal]="orientation === 'horizontal'">
	<div class="col-energy">
		<div class="nutrition-circle-wrapper">
			<svg xmlns="http://www.w3.org/2000/svg" style="width:100%;" viewBox="0 0 288 145" fill="none">
				<path d="M267.325 144.845C279.296 144.845 289.167 135.087 287.378 123.25C282.794 92.9184 268.633 64.6242 246.677 42.668C219.578 15.569 182.824 0.344974 144.5 0.344971C106.176 0.344968 69.4221 15.569 42.3231 42.668C15.2241 69.767 5.78673e-06 106.521 0 144.845H43.35C43.35 118.018 54.0069 92.2904 72.9762 73.3211C91.9455 54.3518 117.673 43.695 144.5 43.695C171.327 43.695 197.055 54.3518 216.024 73.3211C229.839 87.1363 239.245 104.536 243.337 123.335C245.883 135.032 255.354 144.845 267.325 144.845Z" fill="#53F0B8" />
			</svg>
		</div>
		<div class="energy-wrapper">
			<div class="kcal">
				<span *ngIf="consumedTotalNutrition?.energyKcal">{{ (consumedTotalNutrition?.energyKcal?.value | number: '1.0-0') }}&nbsp;kcal&nbsp;/<br></span>
				<span class="trainer-value">{{ totalNutrition ? ( totalNutrition.energyKcal.value || 0 | number: '1.0-0' ) + '&nbsp;' + (totalNutrition.energyKcal.uom || 'kcal') : '0&nbsp;kcal' }}</span>
			</div>
			<div class="kj trainer-value">
				{{ totalNutrition ? ( totalNutrition.energyKj.value || 0 | number: '1.0-0') + '&nbsp;' + (totalNutrition.energyKj.uom || 'kJ') : '0&nbsp;kJ' }}
			</div>
			<div class="energy-description">
				Gesamt - Kalorien
			</div>
		</div>
	</div>
	<div class="col-nutrition-value">
		<!-- PROTEINE -->
		<div class="nutrition-value-list">
			<div class="nutrition-value-container">
				<div class="type-value-wrapper">
					<div class="type">
						Proteine
					</div>
					<div class="value">
						<span *ngIf="consumedTotalNutrition?.proteins">{{ (consumedTotalNutrition?.proteins?.value | number: '1.0-0') + '' + consumedTotalNutrition?.proteins?.uom }} / </span>
						<span class="trainer-value">{{ totalNutrition ? totalNutrition.proteins.value + totalNutrition.proteins.uom : '0g' }}</span>
					</div>
				</div>
				<div class="icon-wrapper green">
					<img src="/assets/icons/milkshake.png" />
				</div>
			</div>
			<!-- KOHLENHYDRATE -->
			<div class="nutrition-value-container">
				<div class="type-value-wrapper">
					<div class="type">
						Kohlenhydrate
					</div>
					<div class="value">
						<span *ngIf="consumedTotalNutrition?.carbohydrates">{{ (consumedTotalNutrition?.carbohydrates?.value | number: '1.0-0') + '' + consumedTotalNutrition?.carbohydrates?.uom }} / </span>
						<span class="trainer-value">{{ totalNutrition ? totalNutrition.carbohydrates.value + totalNutrition.carbohydrates.uom : '0g' }}</span>
					</div>
				</div>
				<div class="icon-wrapper orange">
					<img src="/assets/icons/porridge.png" />
				</div>
			</div>
			<!-- FETT -->
			<div class="nutrition-value-container">
				<div class="type-value-wrapper">
					<div class="type">
						Fett
					</div>
					<div class="value">
						<span *ngIf="consumedTotalNutrition?.fat">{{ (consumedTotalNutrition?.fat?.value | number: '1.0-0') + '' + consumedTotalNutrition?.fat?.uom }} / </span>
						<span class="trainer-value">{{ totalNutrition ? totalNutrition.fat.value + totalNutrition.fat.uom : '0g' }}</span>
					</div>
				</div>
				<div class="icon-wrapper red">
					<img src="/assets/icons/pizza.png" />
				</div>
			</div>
		</div>
	</div>
</div>