import { Pipe, PipeTransform } from '@angular/core';
import { DocumentData, DocumentReference } from '@angular/fire/firestore';
import { LicenseService } from './../../core/services/license.service';
import { License } from '../models/license';
import { take } from 'rxjs/operators';

@Pipe({
	name: 'getLicense'
})
export class GetLicensePipe implements PipeTransform {

	license: License;
	cachedRef: any;

	constructor(private licenseSvc: LicenseService) { }

	transform(ref: DocumentReference<DocumentData>, ...args: unknown[]): unknown {
		if (ref && ref !== this.cachedRef) {
			this.cachedRef = ref;
			this.licenseSvc.getById(ref.id)
				.pipe(take(1))
				.subscribe(license => {
					this.license = license;
				});
		}
		return ref;
	}

}
