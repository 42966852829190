import { Injectable, Injector } from '@angular/core';
import { Exercise } from '../../shared/models/exercise';
import { FirestoreService } from './firestore.service';

export const EXERCISE_PATH: string = 'exercises';

@Injectable({
	providedIn: 'root'
})
export class ExerciseService extends FirestoreService<Exercise> {

	constructor(injector: Injector) {
		super(injector, EXERCISE_PATH);
	}

}
