import { Injectable, inject } from '@angular/core';
import { Customer } from '../../shared/models/customer';
import { User } from '../../shared/models/user';
import { CUSTOMERS_PATH } from './customer.service';
import { MESSAGE_ATTACHMENTS_PATH } from './message.service';
import { Firestore, collection, doc } from '@angular/fire/firestore';
import { Storage, UploadMetadata, ref, uploadBytes, listAll, uploadString, deleteObject } from '@angular/fire/storage';

@Injectable({
	providedIn: 'root'
})
export class FileUploadService {

	private storage: Storage = inject(Storage);
	private firestore: Firestore = inject(Firestore);

	createCustomMediaObjectFolder(path: string, folderName: string) {
		path += '/' + folderName + '/empty';
		const newFolderRef = ref(this.storage, path);
		return uploadString(newFolderRef, '');
	}

	async getMediaObjectFolders(path: string) {
		const customerMediaObjectListRef = ref(this.storage, path);
		const list = await listAll(customerMediaObjectListRef);
		return {
			currentFolderPath: path,
			currentFolderList: list.prefixes,
		}
	}

	deleteMediaObjectFolder(folderPath: string) {
		folderPath += '/empty';
		const customerMediaObjectFolderRef = ref(this.storage, folderPath);
		return deleteObject(customerMediaObjectFolderRef);
	}

	uploadFile(file: File, type: 'image' | 'video', path: string, id: string) {
		const fileUploadPath = `${path}/${id}/${type}s/${file.name}`;
		const storageRef = ref(this.storage, fileUploadPath);
		//return this._storage.upload(fileUploadPath, file);
		return uploadBytes(storageRef, file);
	}

	uploadExercise(file: File, id: string, sex: 'female' | 'male', type: 'image' | 'video', trainerId?: string) {
		let fileUploadPath = `media/exercises/${id}/${sex}/${type}/${file.name}`;
		if (trainerId) {
			fileUploadPath = 'trainers/' + trainerId + '/' + fileUploadPath;
		}
		const storageRef = ref(this.storage, fileUploadPath);
		return uploadBytes(storageRef, file);
	}

	uploadMediaObject(file: File, title: string, customer: Customer, user: User, currentFolderPath?: string) {
		const path = currentFolderPath + '/' + this.createId() + file.name;
		const metaData: UploadMetadata = {
			customMetadata: {
				customerId: customer.ref.id,
				authorUserId: user.ref.id,
				title,
			}
		}
		const storageRef = ref(this.storage, path);
		return uploadBytes(storageRef, file, metaData);
	}

	uploadVoiceMessage(file: File, customer: Customer, user: User) {
		const path = `${CUSTOMERS_PATH}/${customer.ref.id}/${MESSAGE_ATTACHMENTS_PATH}/${this.createId()}_${file.name}`;
		const metaData: UploadMetadata = {
			customMetadata: {
				customerId: customer.ref.id,
				authorUserId: user.ref.id,
				recipientUserId: customer.ref.id,
			},
		}
		const storageRef = ref(this.storage, path);
		return uploadBytes(storageRef, file, metaData);
	}

	private createId() {
		const collectionRef = collection(this.firestore, 'collection');
		return doc(collectionRef).id;
	}

}
