import { Injectable, Injector } from '@angular/core';
import { Trainer } from '../../shared/models/trainer';
import { combineLatest, map, take } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { User } from '../../shared/models/user';
import { UserService } from './user.service';
import { doc, docSnapshots, setDoc } from '@angular/fire/firestore';
import { convertSnap } from '../utils/db-utils';

export const TRAINERS_PATH: string = 'trainers';
export const TAINER_SETTING_PATH = 'trainerSettings';
export const TRAINER_CUSTOMER_WELCOME_EMAIL = 'customerWelcomeEmail';

@Injectable({
  providedIn: 'root'
})
export class TrainerService extends FirestoreService<Trainer> {

  constructor(
    injector: Injector,
    private userSvc: UserService,
  ) {
    super(injector, TRAINERS_PATH);
  }

  getTrainersWithoutUserAccount() {
    const trainers$ = this.getAll('firstName');
    const users$ = this.userSvc.getAll();

    return combineLatest([trainers$, users$])
      .pipe(
        take(1),
        map(results => {
          const trainers: Trainer[] = results[0];
          const users: User[] = results[1];
          return trainers.filter(trainer => !users.find(user => user.ref.id === trainer.ref.id));
        })
      );
  }

  setCustomerWelcomeMailForTrainer(trainer: Trainer, customerWelcomeEmail: Partial<CustomerWelcomeEmail>) {
    const customerWelcomeEmailRef = this.getCustomerWelcomeMailForTrainerRef(trainer);
    return setDoc(customerWelcomeEmailRef, customerWelcomeEmail);
  }

  getCustomerWelcomeMailForTrainer(trainer: Trainer) {
    const customerWelcomeEmailRef = this.getCustomerWelcomeMailForTrainerRef(trainer);
    return docSnapshots(customerWelcomeEmailRef).pipe(
      map(snap => {
        if (!snap.exists()) {
          return null;
        }
        return convertSnap<CustomerWelcomeEmail>(snap);
      })
    );
  }

  private getCustomerWelcomeMailForTrainerRef(trainer: Trainer) {
    const customerWelcomeEmailPath = `${trainer.ref.path}/${TAINER_SETTING_PATH}/${TRAINER_CUSTOMER_WELCOME_EMAIL}`;
    return doc(this.firestore, customerWelcomeEmailPath)
  }

}


export interface CustomerWelcomeEmail {
  headline: string;
  showTrainerImage: boolean;
  text: string;
}