import { Injectable, Injector } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { Message } from '../../shared/models/message';
import { convertSnaps } from '../utils/db-utils';
import { FirestoreService } from './firestore.service';
import { Timestamp, serverTimestamp, query, where, collectionSnapshots, DocumentReference, DocumentData } from '@angular/fire/firestore';

export const MESSAGES_PATH: string = 'messages';
export const MESSAGE_ATTACHMENTS_PATH = 'messageAttachments';

@Injectable({
	providedIn: 'root'
})
export class MessageService extends FirestoreService<Message> {

	constructor(
		injector: Injector,
	) {
		super(injector, MESSAGES_PATH);
	}

	getMessagesForCustomerAndTrainer(fromUserRef: DocumentReference<DocumentData>, toUserRef: DocumentReference<DocumentData>) {
		const messageCollectionRef = this.getCollection();

		const sentMessages$ = collectionSnapshots(query(messageCollectionRef, where('from', '==', fromUserRef), where('to', '==', toUserRef)))
			.pipe(
				map(snaps => convertSnaps<Message>(snaps))
			);

		const receivedMessages$ = collectionSnapshots(query(messageCollectionRef, where('from', '==', toUserRef), where('to', '==', fromUserRef)))
			.pipe(
				map(snaps => convertSnaps<Message>(snaps))
			);

		return combineLatest([sentMessages$, receivedMessages$])
			.pipe(
				map(results => {
					const sentMessages: Message[] = results[0];
					const receivedMessages: Message[] = results[1];
					const allMessages = [...sentMessages, ...receivedMessages].sort((a, b) => {
						if (a.createdAt && b.createdAt) {
							const date1 = a.createdAt;
							const date2 = b.createdAt;
							return date1.toMillis() - date2.toMillis();
						}
						return 0;
					});
					return allMessages;
				})
			)
	}

	createMessage(fromUserRef: DocumentReference<unknown>, toUserRef: DocumentReference<unknown>, message: string) {
		const newMessage: Partial<Message> = {
			createdAt: serverTimestamp() as Timestamp,
			from: fromUserRef,
			message: message,
			to: toUserRef
		};
		return this.add(newMessage);
	}

}
