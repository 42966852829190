import { Pipe, PipeTransform } from '@angular/core';
import { Trainer } from '../models/trainer';

@Pipe({
	name: 'trainer'
})
export class TrainerPipe implements PipeTransform {

	transform(trainer?: Trainer | null, value?: 'fullName' | 'formattedAddress'): string {
		if (!trainer) {
			return '-';
		}

		switch (value) {
			case 'fullName': {
				return `${trainer.firstName} ${trainer.lastName}`.trim();
			}
			case 'formattedAddress': {
				return trainer.address?.formattedAddress || '-';
			}
			default: {
				return `${trainer.firstName} ${trainer.lastName}`.trim();
			}
		}
	}

}
