import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { FileUploadService } from '../../../core/services/file-upload.service';
import { UploadResult } from '@angular/fire/storage';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {

  @Input() type: 'image' | 'video';
  @Input() path: 'trainers' | 'exercises' | 'challenges' | 'users';
  @Input() sex?: 'female' | 'male';
  @Input() id: string;
  @Input() readonly: boolean = false;
  @Input() trainerId?: string;

  fileRef: string;
  uploadPercentage$: Observable<number | undefined>;
  isLoading: boolean = false;

  constructor(
    private fileUploadSvc: FileUploadService,
    private snackbarSvc: SnackbarService,
  ) { }

  writeValue(value: string) {
    this.fileRef = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  async uploadFile(event: any) {
    const file: File = event.target.files[0];
    let task: Promise<UploadResult>;
    this.isLoading = true;
    if (this.path === 'exercises' && this.sex) {
      const requirements = await this.getVideoDuration(file) as any;
      if (requirements && typeof requirements.duration === 'number' && requirements.duration > 60) {
        this.snackbarSvc.showError('Video zu lang (Maximal 60 Sekunden)');
        return;
      }
      if (requirements && typeof requirements.ratio === 'number' && (requirements.ratio < 1.68 || requirements.ratio > 1.88)) {
        this.snackbarSvc.showError('Falsches Seitenverältnis (kein 16:9)');
        return;
      }
      task = this.fileUploadSvc.uploadExercise(file, this.id, this.sex, this.type, this.trainerId);
    } else {
      task = this.fileUploadSvc.uploadFile(file, this.type, this.path, this.id);
    }


    //this.uploadPercentage$ = task.percentageChanges();
    task.then(res => {
      this.isLoading = false;
      this.writeValue(res.ref.fullPath);
      this.propagateChange(this.fileRef);
    });
  }

  deleteFile() {
    if (confirm('Soll die Datei wirklich gelöscht werden?')) {
      this.writeValue('');
      this.propagateChange('');
    }
  }

  async getVideoDuration(file: File) {
    return new Promise((resolve, reject) => {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve({
          duration: video.duration,
          ratio: video.videoWidth / video.videoHeight,
        });
      }
      video.src = URL.createObjectURL(file);
    });
  }

}
