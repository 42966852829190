import { Pipe, PipeTransform } from '@angular/core';
import { Feedback } from '../models/feedback';

@Pipe({
	name: 'feedback'
})
export class FeedbackPipe implements PipeTransform {

	transform(feedback: Feedback, ...args: unknown[]): string {
		switch (feedback.difficulty) {
			case 0:
				return feedback.message || '-';
			case 1:
				return 'zu einfach';
			case 2:
				return 'perfekt';
			case 3:
				return 'zu schwierig';
			default:
				return '-';
		}
	}
}
