import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
	selector: 'app-edit-user-email-dialog',
	templateUrl: './edit-user-email-dialog.component.html',
	styleUrls: ['./edit-user-email-dialog.component.scss']
})
export class EditUserEmailDialogComponent implements OnInit {


	emailForm = new UntypedFormGroup({
		email: new UntypedFormControl('', [Validators.required, Validators.email]),
		confirmEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
	}, { validators: validateControlsMatching('email', 'confirmEmail') });
	isLoading: boolean = false;
	uid: string;

	constructor(
		private authSvc: AuthService,
		private snackbarSvc: SnackbarService,
		private dialogRef: MatDialogRef<EditUserEmailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: { uid: string }
	) {
		this.uid = data.uid;
	}

	ngOnInit(): void { }

	async onSubmit() {
		if (this.emailForm.valid) {
			this.isLoading = true;
			const email: string = this.emailForm.value.email;
			try {
				await this.authSvc.updateAuthUserEmail(this.uid, email);
				this.snackbarSvc.showSuccess('E-Mail Adresse geändert.');
				this.dialogRef.close();
			} catch (err: any) {
				this.snackbarSvc.showError(err.message);
				this.isLoading = false;
			}
		}
	}

}


export function validateControlsMatching(controlName1: string, controlName2: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const v1 = control.get(controlName1)?.value;
		const v2 = control.get(controlName2)?.value;
		const match = v1 === v2;
		return match ? null : { notMatching: { value: `${v1} !== ${v2}` } };
	};
}