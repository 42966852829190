<h2 mat-dialog-title>E-Mail ändern</h2>

<mat-dialog-content class="mat-typography">
	<form [formGroup]="emailForm">
		<mat-form-field>
			<mat-label>E-Mail</mat-label>
			<input matInput formControlName="email">
		</mat-form-field>
		<mat-form-field>
			<mat-label>E-Mail (wiederholen)</mat-label>
			<input matInput formControlName="confirmEmail">
		</mat-form-field>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Abbrechen</button>
	<button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="emailForm.invalid || isLoading">
		<mat-spinner color="accent" diameter="24" *ngIf="isLoading"></mat-spinner>
		E-Mail ändern
	</button>
</mat-dialog-actions>