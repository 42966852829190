import { Pipe, PipeTransform } from '@angular/core';
import { MediaObject } from '../models/media-object';
import { getSubstringUntilLastSlash } from 'src/app/core/utils/common-functions';

@Pipe({
	name: 'mediaObjectFolder'
})
export class MediaObjectFolderPipe implements PipeTransform {

	transform(mediaObjects: MediaObject[] | null, currentFolderPath?: string): MediaObject[] | null {
		if (!mediaObjects) return mediaObjects;
		return mediaObjects.filter(mediaObject => getSubstringUntilLastSlash(mediaObject.fileRef) === currentFolderPath);
	}

}
