import { Component, Input, OnInit } from '@angular/core';
import { NutritionPlan } from '../../models/nutrition-plan';
import { Recipe } from '../../models/recipe';
import { TotalNutrition } from '../../models/total-nutrition';
import { Ingredient } from '../../models/ingredient';
import { ConsumedMeal } from '../../models/consumed-meal';
import { ConsumedMealService } from 'src/app/core/services/consumed-meal.service';
import { NutritionPlanService } from 'src/app/core/services/nutrition-plan.service';

@Component({
	selector: 'app-nutrition-plan-values',
	templateUrl: './nutrition-plan-values.component.html',
	styleUrls: ['./nutrition-plan-values.component.scss']
})
export class NutritionPlanValuesComponent implements OnInit {

	@Input() orientation: 'horizontal' | 'vertical' = 'vertical';

	private _nutritionPlan: NutritionPlan;
	@Input() set nutritionPlan(nutritionPlan: NutritionPlan) {
		this._nutritionPlan = nutritionPlan;
		this.totalNutrition = nutritionPlan.dailyNutritionGoal;
		this.updatConsumedNutrition();
	}
	get nutritionPlan() {
		return this._nutritionPlan;
	}

	@Input() set recipe(recipe: Recipe) {
		this.totalNutrition = recipe.totalNutrition;
	}

	@Input() set ingredient(ingredient: Ingredient) {
		if (!ingredient.food.energy_kcal_100g) {
			return
		}
		this.totalNutrition = {
			carbohydrates: {
				uom: 'g',
				value: ingredient.food.carbohydrates_100g
			},
			fat: {
				uom: 'g',
				value: ingredient.food.fat_100g
			},
			proteins: {
				uom: 'g',
				value: ingredient.food.proteins_100g
			},
			energyKcal: {
				uom: 'kcal',
				value: ingredient.food.energy_kcal_100g
			},
			energyKj: {
				uom: 'kJ',
				value: ingredient.food.energy_100g
			}
		};
	}

	totalNutrition?: TotalNutrition;

	private _consumedMeals?: ConsumedMeal[];
	@Input() set consumedMeals(consumedMeals: ConsumedMeal[] | undefined) {
		this._consumedMeals = consumedMeals;
		this.updatConsumedNutrition();
	}

	get consumedMeals() {
		return this._consumedMeals;
	}

	@Input() consumedTotalNutrition?: TotalNutrition;

	constructor(
		private consumedMealSvc: ConsumedMealService,
		private nutritionPlanSvc: NutritionPlanService,
	) { }

	ngOnInit(): void { }

	updatConsumedNutrition() {
		if (this.nutritionPlan && this.consumedMeals) {
			if (this.consumedMeals?.length) {
				this.consumedTotalNutrition = this.consumedMealSvc.getConsumedNutritionValues(this.consumedMeals);
			} else {
				this.consumedTotalNutrition = undefined;
			}
		} else {
			this.consumedTotalNutrition = undefined;
		}
	}

}
