<div class="row justify-content-center">
	<div class="col-md-6">
		<h1>Login</h1>
		<mat-card>
			<form [formGroup]="loginForm">
				<mat-form-field>
					<mat-label>Email</mat-label>
					<input matInput formControlName="email">
				</mat-form-field>
				<mat-form-field>
					<mat-label>Password</mat-label>
					<input matInput type="password" formControlName="password">
				</mat-form-field>
				<div class="password-reset-button-bar">
					<button mat-flat-button color="primary" (click)="onLogin()" [disabled]="!loginForm.valid">Login</button>
					<span class="spacer"></span>
					<button mat-button (click)="onForgotPasswort()">Passwort vergessen</button>
				</div>
			</form>
		</mat-card>
	</div>
</div>