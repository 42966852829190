import { Pipe, PipeTransform } from '@angular/core';
import { Food } from '../models/food';
import { Ingredient } from '../models/ingredient';
import { UomValuePair } from '../models/uom-value-pair';

@Pipe({
  name: 'ingredients'
})
export class IngredientsPipe implements PipeTransform {

  transform(ingredients: Ingredient[], totalNutritionalValue: keyof Food): number {
    const totalNutrition = ingredients.reduce((acc, ingredient) => {
      const ingredientInGram = this.getGramValue(ingredient.quantity);
      const ratio = ingredientInGram / 100;
      const nutritionValue = ingredient.food[totalNutritionalValue];
      return acc + (typeof nutritionValue === 'number' ? nutritionValue * ratio : 0)
    }, 0);
    return Math.round(totalNutrition * 100) / 100;
  }

  private getGramValue(uom: UomValuePair) {
    switch (uom.uom) {
      case 'g':
      case 'ml':
        return uom.value;
      case 'kg':
      case 'l':
        return uom.value * 1000;
      case 'TL':
        return uom.value * 5;
      case 'EL':
        return uom.value * 15;
      default:
        return 0;
    }
  }

}
