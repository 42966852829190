import { Pipe, PipeTransform } from '@angular/core';
import { MEAL_TYPE_VALUES, MealType } from '../models/meal';

@Pipe({
	name: 'mealType'
})
export class MealTypePipe implements PipeTransform {

	transform(mealType?: MealType | string, ...args: unknown[]): string {
		if (MEAL_TYPE_VALUES.includes(mealType as any)) {
			switch (mealType) {
				case MealType.BREAKFAST:
					return 'Frühstück';
				case MealType.LUNCH:
					return 'Mittagessen';
				case MealType.DINNER:
					return 'Abendessen';
				case MealType.SNACK:
					return 'Snack';
				case MealType.CUSTOM:
					return 'andere';
			}
		}
		return mealType || '';
	}

}
