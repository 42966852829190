import { Injectable, Injector } from '@angular/core';
import { License } from '../../shared/models/license';
import { FirestoreService } from './firestore.service';

export const LICENSES_PATH: string = 'licenses';

@Injectable({
	providedIn: 'root'
})
export class LicenseService extends FirestoreService<License> {

	constructor(injector: Injector) {
		super(injector, LICENSES_PATH);
	}

}
