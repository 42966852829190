import { DocumentData, QueryDocumentSnapshot } from "@angular/fire/firestore";


export function convertSnaps<T>(snaps: QueryDocumentSnapshot<DocumentData>[]) {
	return <T[]>snaps.map(snap => convertSnap<T>(snap))
}

export function convertSnap<T>(snap: QueryDocumentSnapshot<DocumentData>) {
	return <T>{
		...snap.data() as T,
		ref: snap.ref,
	};
}
