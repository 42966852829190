<!-- DIALOG TITLE -->
<div mat-dialog-title>
	<div class="row justify-content-between">
		<div class="col-10">
			<h1>{{ recipe.name }}</h1>
		</div>
		<div class="col-2" class="text-end">
			<button mat-icon-button mat-dialog-close>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</div>
<div mat-dialog-content>
	<p>{{ recipe.preparationInstructions }}</p>
	<p>
		<strong>Dauer:</strong> {{ recipe.preparationTime.value + recipe.preparationTime.uom }}
	</p>
	<div class="row">
		<div class="col-6">
			<h2>Zutaten</h2>
			<table>
				<tr *ngFor="let ingredient of recipe.ingredients">
					<td>{{ ingredient.quantity.value + ingredient.quantity.uom }}</td>
					<td>{{ ingredient.food.product_name_de || ingredient.food.product_name || '-' }}</td>
				</tr>
			</table>
		</div>
		<div class="col-6">
			<h2>Nährwerte</h2>
			<table style="text-align: left;">
				<tr>
					<th>kcal (kJ)</th>
					<td>{{ recipe.totalNutrition.energyKcal.value }} ({{ recipe.totalNutrition.energyKj.value }})</td>
				</tr>
				<tr>
					<th>Eiweiß</th>
					<td>{{ recipe.totalNutrition.proteins.value + recipe.totalNutrition.proteins.uom }}</td>
				</tr>
				<tr>
					<th>Fett</th>
					<td>{{ recipe.totalNutrition.fat.value + recipe.totalNutrition.fat.uom }}</td>
				</tr>
				<tr>
					<th>Kohlenhydrate</th>
					<td>{{ recipe.totalNutrition.carbohydrates.value + recipe.totalNutrition.carbohydrates.uom }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>