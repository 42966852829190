import { Injectable, Injector } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { Weight } from 'src/app/shared/models/weight';

export const WEIGHT_RECORDS_PATH: string = 'weightRecords';

@Injectable({
	providedIn: 'root'
})
export class WeightRecordService extends FirestoreService<Weight> {

	constructor(
		injector: Injector,
	) {
		super(injector, WEIGHT_RECORDS_PATH);
	}

}
