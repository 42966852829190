import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

	constructor(private _snackBar: MatSnackBar) { }

	showSuccess(message: string) {
		this.showBasicSnackBar(message, 'green');
	}

	showError(message: string) {
		this.showBasicSnackBar(message, 'red');
	}

	showMessage(message: string) {
		this.showBasicSnackBar(message);
	}

	private showBasicSnackBar(message: string, color: 'default' | 'red' | 'green' = 'default') {
		this._snackBar.open(message, undefined, {
			duration: 4000,
			panelClass: [color + '-snackbar'],
		});
	}

}
