import { Pipe, PipeTransform } from '@angular/core';
import { FormContainer } from '../models/form-container';

@Pipe({
  name: 'filterFormContainers'
})
export class FilterFormContainersPipe implements PipeTransform {

  transform(formContainers: FormContainer[] | null, date: Date): FormContainer[] | null {
    if (!formContainers) {
      return formContainers;
    }
    return formContainers.filter(formContainer => formContainer.scheduledAt.toDate().toDateString() === date.toDateString());
  }

}
