import { Injectable, Injector } from '@angular/core';
import { Customer } from '../../shared/models/customer';
import { NutritionPlan } from '../../shared/models/nutrition-plan';
import { convertSnaps } from '../utils/db-utils';
import { FirestoreService } from './firestore.service';
import { Meal } from '../../shared/models/meal';
import { DocumentData, DocumentReference, doc, getDocs, limit, orderBy } from '@angular/fire/firestore';
import { collection, collectionSnapshots, runTransaction, query, where, writeBatch } from '@angular/fire/firestore';
import { firstValueFrom, map } from 'rxjs';
import { getDaysDifference, getToday } from '../utils/date-utils';

export const NUTRITION_PLAN_PATH: string = 'nutritionPlans';
export const MEAL_PATH: string = 'meals';

@Injectable({
	providedIn: 'root'
})
export class NutritionPlanService extends FirestoreService<NutritionPlan> {

	constructor(injector: Injector) {
		super(injector, NUTRITION_PLAN_PATH);
	}

	duplicateNutritionPlan(nutritionPlan: NutritionPlan, refDestination?: DocumentReference<unknown>) {
		return runTransaction(this.firestore, async t => {
			const nutritionPlanMeals = await firstValueFrom(this.getMealsForNutritionPlan(nutritionPlan));
			const nutritionPlanDestinationRef = refDestination ? doc(collection(this.firestore, refDestination.path, NUTRITION_PLAN_PATH)) : doc(nutritionPlan.ref.parent);
			let duplicatedNutritionPlan: Partial<NutritionPlan> = Object.assign({}, nutritionPlan);
			if (refDestination) {
				duplicatedNutritionPlan.parentNutritionPlan = nutritionPlan.ref;
			} else {
				duplicatedNutritionPlan.name = nutritionPlan.name + ' - Kopie';
			}
			delete duplicatedNutritionPlan.ref;
			t.set(nutritionPlanDestinationRef, duplicatedNutritionPlan);
			nutritionPlanMeals.map(meal => {
				const duplicateMeal: any = Object.assign({}, meal)
				delete duplicateMeal.ref;
				t.set(doc(collection(this.firestore, nutritionPlanDestinationRef.path, MEAL_PATH)), meal);
			});
			return nutritionPlanDestinationRef;
		});
	}

	getNutritionPlansForCustomer(customer: Customer, date: Date) {
		const nutritionPlanCollectionRef = collection(this.firestore, customer.ref.path, NUTRITION_PLAN_PATH);
		return collectionSnapshots(query(nutritionPlanCollectionRef, where('endDate', '>=', new Date(date.toDateString()))))
			.pipe(
				map(snaps => convertSnaps<NutritionPlan>(snaps)),
				map(nutritionPlans => nutritionPlans.filter(nutritionPlan => {
					if (nutritionPlan.startDate && nutritionPlan.endDate) {
						return new Date(nutritionPlan.startDate.toDate().setHours(0, 0, 0, 0)) <= new Date(date.setHours(0, 0, 0, 0))
					}
					return true;
				}))
			);
	}

	getMealsForNutritionPlan(nutritionPlan: NutritionPlan) {
		const mealCollectionRef = collection(this.firestore, nutritionPlan.ref.path, MEAL_PATH);
		return collectionSnapshots(mealCollectionRef)
			.pipe(
				map(snaps => convertSnaps<Meal>(snaps)),
				map(meals => meals.sort((a, b) => a.index !== undefined && b.index !== undefined ? a.index - b.index : 0))
			);
	}

	async updateNutritionPlan(nutritionPlanRef: DocumentReference<DocumentData>, nutritionPlan: Partial<NutritionPlan>, meals: Meal[]) {
		if (!nutritionPlan.endDate) delete nutritionPlan.endDate;
		if (!nutritionPlan.startDate) delete nutritionPlan.startDate;

		const mealCollectionRef = collection(this.firestore, nutritionPlanRef.path, MEAL_PATH);
		const oldMeals = await getDocs(mealCollectionRef);

		const batch = writeBatch(this.firestore);
		oldMeals.docs.map(doc => batch.delete(doc.ref));
		meals.map(meal => batch.set(doc(mealCollectionRef), meal));
		batch.update(nutritionPlanRef, nutritionPlan);

		return batch.commit();
	}

	async deleteNutritionPlan(nutritionPlanRef: DocumentReference<DocumentData>) {
		const mealCollectionRef = collection(this.firestore, nutritionPlanRef.path, MEAL_PATH);
		const oldMeals = await getDocs(mealCollectionRef);

		const batch = writeBatch(this.firestore);
		oldMeals.docs.map(doc => batch.delete(doc.ref));
		batch.delete(nutritionPlanRef);

		return batch.commit();
	}

	getRemainingNutritionPlanDays(customerRef: DocumentReference<DocumentData>) {
		const collectionRef = collection(this.firestore, customerRef.path, NUTRITION_PLAN_PATH);
		const queryRef = query(collectionRef, orderBy('endDate', 'desc'), where('endDate', '>=', getToday()), limit(1));
		return collectionSnapshots(queryRef).pipe(map(nutrutionPlans => {
			if (nutrutionPlans.length > 0) {
				const nutrutionPlan = nutrutionPlans[0].data() as NutritionPlan;
				return getDaysDifference(new Date(), nutrutionPlan.endDate!.toDate());
			} else {
				return null;
			}
		}));
	}

}
