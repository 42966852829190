import { Component, Input, OnInit } from '@angular/core';
import { DocumentData, DocumentReference, refEqual } from '@angular/fire/firestore';
import { ControlContainer, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { License } from '../../models/license';
import { Tag } from '../../models/tag';

@Component({
	selector: 'app-reference-select',
	templateUrl: './reference-select.component.html',
	styleUrls: ['./reference-select.component.scss']
})
export class ReferenceSelectComponent implements OnInit {

	@Input() docs: Tag[] | License[] | null;
	@Input() patch?: DocumentReference<DocumentData>[];
	refFormArray: UntypedFormArray;

	constructor(private controlContainer: ControlContainer) { }

	ngOnInit(): void {
		this.refFormArray = this.controlContainer.control as UntypedFormArray;
		this.patchFormArray();
	}

	patchFormArray() {
		if (this.patch) {
			this.patch.forEach(tagRef => this.refFormArray.push(new UntypedFormControl(tagRef)));
		}
	}

	isChecked(ref: DocumentReference<DocumentData>) {
		return !!this.refFormArray.controls.find((control => refEqual(ref, control.value)));
	}

	onClick(ev: MatCheckboxChange, ref: DocumentReference<DocumentData>) {
		if (ev.checked) {
			this.refFormArray.push(new UntypedFormControl(ref));
		} else {
			const i = this.refFormArray.controls.findIndex((control => refEqual(ref, control.value)));
			this.refFormArray.removeAt(i);
		}
	}

}
