<!-- DISPLAY DEFAULT IMAGE -->
<img *ngIf="!fileRef" src="/assets/img/user_placeholder.png" />
<!-- DISPLAY IMAGE-->
<img *ngIf="fileRef && type === 'image'" [src]="fileRef | downloadURL" />
<!-- DISPLAY VIDEO -->
<video *ngIf="fileRef && type === 'video'" [src]="fileRef | downloadURL" controls preload="metadata">
	Your browser does not support the video tag.
</video>
<!-- UPLOAD IN PROGRESS -->
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="file-picker-container" *ngIf="!readonly">
	<!-- DISPLAY FILE PICKER -->
	<input *ngIf="!fileRef" type="file" (change)="uploadFile($event)" [accept]="type + '/*'" />
	<span *ngIf="!fileRef && type === 'video'" class="video-hint">(max. 60 Sekunden & Format 16:9)</span>
	<!-- DISPLAY DELETE BUTTON -->
	<button mat-icon-button class="btn-alert" (click)="deleteFile()" *ngIf="fileRef">
		<mat-icon>delete</mat-icon>
	</button>
</div>

<!-- <div class="center-parent">
	<ng-container *ngIf="(uploadPercentage$ | async) as percentage">
		<ng-container *ngIf="percentage < 100">
			<mat-progress-spinner mode="determinate" [value]="percentage" class="center-child"></mat-progress-spinner>
			<span class="center-child">{{ percentage / 100 | percent }}</span>
		</ng-container>
	</ng-container>
</div> -->