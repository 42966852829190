import { Timestamp, DocumentReference } from '@angular/fire/firestore';
import { Recipe } from "./recipe";

export interface Meal {
	consumedAt?: Timestamp;
	imageRef?: string;
	index?: number;
	kcalAdjustment: number;
	mealType?: MealType | string;
	notes: string;
	parentRecipe: Recipe,
	parentRecipeRef?: DocumentReference<Recipe>;
	recipe: Recipe;
}

export enum MealType {
	BREAKFAST = 'breakfast',
	LUNCH = 'lunch',
	DINNER = 'dinner',
	SNACK = 'snack',
	CUSTOM = 'custom',
}

export const MEAL_TYPE_VALUES = Object.values(MealType);