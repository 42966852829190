import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Customer } from '../../models/customer';
import { firstValueFrom } from 'rxjs';
import { WeightRecordService } from 'src/app/core/services/weight-record.service';
import { Chart, ChartConfiguration } from 'chart.js';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-progress-chart',
	templateUrl: './progress-chart.component.html',
	styleUrls: ['./progress-chart.component.scss']
})
export class ProgressChartComponent {

	private _customer?: Customer;
	@Input() set customer(customer: Customer | undefined) {
		this._customer = customer;
		this.plotWeight();
	}
	get customer() {
		return this._customer;
	}

	chart?: Chart;
	isEmpty: boolean = true;

	@ViewChild('chart') chartRef?: ElementRef;

	constructor(
		private weightRecordSvc: WeightRecordService,
		private datePipe: DatePipe,
	) { }

	async plotWeight() {
		if (!this.customer) return;
		if (this.chart) {
			console.log('Destroy')
			this.chart.destroy();
			this.chart = undefined;
			this.isEmpty = true;
		}

		const weights = await firstValueFrom(this.weightRecordSvc.getAll('createdAt', 'asc', this.customer.ref));
		const data: any[] | undefined = weights.map(weight => {
			return {
				x: this.datePipe.transform(weight.createdAt.toDate(), 'dd.MM.yy'),
				y: weight.value,
			}
		});
		if (!data?.length) {
			return;
		}
		this.isEmpty = false;

		const cfg: ChartConfiguration = {
			type: 'line',
			data: {
				datasets: [{
					label: 'Gewicht',
					data: data,
					borderColor: '#F5E2BD',
				}]
			},
			options: {
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							align: 'start',
							maxRotation: 0,
							maxTicksLimit: 6,
							color: '#fff',
						},
					},
					y: {
						ticks: {
							callback: (value, index, ticks) => value + ' kg',
							color: '#fff',
							precision: 1,
						},
					}
				},
				plugins: {
					legend: {
						display: false,
					},
				}
			}
		};
		if (!this.chartRef?.nativeElement) return;
		this.chart = new Chart(this.chartRef.nativeElement, cfg);
	}
}
