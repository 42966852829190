import { Pipe, PipeTransform, inject } from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';

@Pipe({
	name: 'downloadURL',
	pure: false
})
export class DownloadURLPipe implements PipeTransform {

	private storage: Storage = inject(Storage);

	cachedDownloadRef: string;
	downloadURL: string = '/assets/img/user_placeholder.png';

	constructor() { }

	transform(downloadRef?: string, ...args: unknown[]): unknown {
		if (downloadRef && downloadRef !== this.cachedDownloadRef) {
			this.cachedDownloadRef = downloadRef;
			const storageRef = ref(this.storage, downloadRef);
			getDownloadURL(storageRef).then(downloadURL => {
				if (downloadURL) {
					this.downloadURL = downloadURL;
				}
			});
		}
		return this.downloadURL;
	}

}
