<h3>Einheit & Pause</h3>
<div class="set-form-row">
  <mat-form-field>
    <mat-label>Einheit</mat-label>
    <mat-select [formControl]="uomControl">
      <mat-option *ngFor="let uomOption of uomOptions" [value]="uomOption">{{ uomOption }}</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container [formGroup]="breakBetweenSetsFormGroup">
    <mat-form-field>
      <mat-label>Pause zw. Sets</mat-label>
      <input matInput formControlName="value" type="number" step="1" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
      <span matSuffix>Sek.</span>
    </mat-form-field>
  </ng-container>
</div>
<h3>Sets*</h3>
<div [formGroup]="form">
  <ng-container formArrayName="assignedSets">
    <ng-container *ngFor="let setForm of assignedSets.controls; let i = index">
      <div class="set-form-row" [formGroup]="getFormGroup(setForm)">
        <span>{{ i + 1 }}.</span>
        <mat-form-field *ngIf="!['Stunden', 'Minuten', 'Sekunden'].includes(uomControl.value)">
          <mat-label>Wiederholungen</mat-label>
          <input matInput formControlName="repetitions" type="number" step="1" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
          <span matSuffix>x</span>
        </mat-form-field>
        <mat-form-field *ngIf="uomControl.value !== 'bodyweight'">
          <mat-label>Wert</mat-label>
          <input matInput formControlName="value" type="number" step="1" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
          <span matSuffix>{{ uomControl.value || 'n/a' }}</span>
        </mat-form-field>
        <button mat-icon-button [disabled]="assignedSets.length < 2" (click)="removeExerciseExecution(i)" color="primary" aria-label="Remove Set">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
  <button mat-button (click)="addExerciseExecution()" color="primary">
    <mat-icon>add_circle</mat-icon>
    Set hinzufügen
  </button>
</div>