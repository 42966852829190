<h2 mat-dialog-title>Passwort zurücksetzen</h2>

<mat-dialog-content class="mat-typography">
	<form [formGroup]="passwordResetForm">
		<mat-form-field>
			<mat-label>Email</mat-label>
			<input matInput formControlName="email">
		</mat-form-field>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Abbrechen</button>
	<button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="passwordResetForm.invalid || isLoading">Passwort zurücksetzen</button>
</mat-dialog-actions>