import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DesiredAppointment } from '../models/desired-appointment';

@Pipe({
  name: 'desiredAppointment'
})
export class DesiredAppointmentPipe implements PipeTransform {

	constructor(private datePipe: DatePipe) { }

  transform(desiredAppointment?: DesiredAppointment, ...args: unknown[]): unknown {
		if(desiredAppointment && desiredAppointment.date) {
			return this.datePipe.transform(desiredAppointment.date.toDate(), 'dd.MM.yyyy') + ', ' + desiredAppointment.timeOfDay;
		}
    return '-';
  }

}
