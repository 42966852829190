import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ExerciseExecution } from '../../models/exercise-execution';
import { Exercise } from '../../models/exercise';

@Component({
	selector: 'app-exercise-sets-edit',
	templateUrl: './exercise-sets-edit.component.html',
	styleUrls: ['./exercise-sets-edit.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ExerciseSetsEditComponent),
			multi: true
		}
	]
})
export class ExerciseSetsEditComponent implements OnInit, ControlValueAccessor {

	@Input() breakBetweenSetsFormGroup: UntypedFormGroup;

	uomControl = new UntypedFormControl('', [Validators.required]);
	form = new UntypedFormGroup({
		assignedSets: new UntypedFormArray([])
	})

	uomOptions: string[] = ['bodyweight', 'kg', 'lbs', 'km', 'Meter', 'Stunden', 'Minuten', 'Sekunden'];

	constructor() {
		this.uomControl.valueChanges.subscribe(uom => {
			this.assignedSets.controls.forEach(control => {
				control.get('uom')?.setValue(uom);
				if (uom === 'bodyweight') {
					control.get('value')?.setValue(0);
				}
				if (['Stunden', 'Minuten', 'Sekunden'].includes(uom)) {
					control.get('repetitions')?.setValue(0);
				}
			});
		})

		this.assignedSets.valueChanges.subscribe(val => {
			this.propagateChange(val);
		})
	}

	ngOnInit(): void { }

	get assignedSets() {
		return this.form.controls['assignedSets'] as UntypedFormArray;
	}

	getFormGroup(control: AbstractControl) {
		return control as UntypedFormGroup;
	}

	addExerciseExecution(exerciseExecution?: ExerciseExecution) {
		let repetitions: string | number = '';
		let uom = '';
		let value: string | number = '';

		if (!exerciseExecution) {
			uom = this.uomControl.value;
			if (this.assignedSets.length > 0) {
				const lastControlValue = this.assignedSets.at(this.assignedSets.length - 1).value;
				repetitions = lastControlValue.repetitions;
				value = lastControlValue.value;
			}
		} else {
			repetitions = exerciseExecution.repetitions;
			uom = exerciseExecution.uom;
			value = exerciseExecution.value;
		}

		const set = new UntypedFormGroup({
			repetitions: new UntypedFormControl(repetitions, [Validators.required]),
			uom: new UntypedFormControl(uom, [Validators.required]),
			value: new UntypedFormControl(value, [Validators.required]),
		});
		this.assignedSets.push(set);
	}

	removeExerciseExecution(index: number) {
		this.assignedSets.removeAt(index);
	}

	writeValue(exerciseExecutions?: ExerciseExecution[]): void {
		this.assignedSets.clear();
		if (!exerciseExecutions?.length) {
			this.addExerciseExecution();
		} else {
			const firstExercise = exerciseExecutions[0];
			if (firstExercise.uom) {
				this.uomControl.setValue(firstExercise.uom);
			}
			exerciseExecutions.forEach(exerciseExecution => {
				this.addExerciseExecution(exerciseExecution)
			});
		}
	}

	propagateChange = (_: any) => { };

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void { }

	setDisabledState?(isDisabled: boolean): void { }

}


export function validateSetsInput(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const formArray = control as UntypedFormArray;
		const assignedSets = formArray.value as ExerciseExecution[];
		if (assignedSets.length === 0) {
			return { setsEmpty: true };
		}
		for (let i = 0; i < assignedSets.length; i++) {
			const assignedSet = assignedSets[i];
			if (typeof assignedSet.repetitions !== 'number' || typeof assignedSet.value !== 'number' || !assignedSet.uom) {
				return { incompleteSetEntry: true };
			}
		}
		return null;
	};
}