import { Injectable, Injector } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { DeviceRecord } from 'src/app/shared/models/device-record';
import { DocumentReference, DocumentData, collection, query, orderBy, limit, collectionSnapshots } from "@angular/fire/firestore";
import { getDaysDifference } from '../utils/date-utils';
import { map } from 'rxjs';

export const DEVICE_RECORDS_PATH: string = 'deviceRecords';

@Injectable({
	providedIn: 'root'
})
export class DeviceRecordService extends FirestoreService<DeviceRecord> {

	constructor(injector: Injector) {
		super(injector, DEVICE_RECORDS_PATH);
	}

	getLastLoginDayCount(customerRef: DocumentReference<DocumentData>) {
		const collectionRef = collection(this.firestore, customerRef.path, DEVICE_RECORDS_PATH);
		const queryRef = query(collectionRef, orderBy('deviceInfo.createdAt', 'desc'), limit(1));
		return collectionSnapshots(queryRef).pipe(map(deviceRecords => {
			if (deviceRecords.length > 0) {
				const deviceRecord = deviceRecords[0].data() as DeviceRecord;
				return getDaysDifference(new Date(), deviceRecord.deviceInfo.createdAt.toDate());
			} else {
				return null;
			}
		}));
	}

}
