<mat-sidenav-container class="sidenav-container">
  <!-- MENU -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(authSvc.isLoggedIn$ | async) && (isHandset$ | async) === false">
    <!-- LOGO -->

    <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto;">
      <div style="padding: 24px;">
        <img src="/assets/img/bodycontrol_logo_white.png" style="max-width: 100%;" />
      </div>
      <!-- NAVIGATION -->
      <div style="flex-grow: 1; display: flex; align-items: center; width: 100%;">
        <mat-nav-list class="nav-list">
          <!-- ADMIN MENU -->
          <ng-container *ngIf="authSvc.isAdmin$ | async">
            <div mat-subheader>Training</div>
            <a mat-list-item routerLink="/trainers" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>directions_run</mat-icon>
              Trainer
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/customers" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>person</mat-icon>
              Kunden
              <div class="active-route-indicator"></div>
            </a>
            <div mat-subheader>Content</div>
            <a mat-list-item routerLink="/challenges" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>sports_score</mat-icon>
              Challenges
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/exercises" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>fitness_center</mat-icon>
              Exercises
              <div class="active-route-indicator"></div>
            </a>
            <!-- <a mat-list-item routerLink="/nutrition-plans">
							<mat-icon mat-list-icon>list_alt</mat-icon>
							Ernährungspläne
						</a> -->
            <a mat-list-item routerLink="/recipes" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>restaurant</mat-icon>
              Rezepte
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/forms/question-list" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>quiz</mat-icon>
              Formular-Fragen
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/licenses" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>verified</mat-icon>
              Lizenzen
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/tags" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>tag</mat-icon>
              Tags
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/trainers/intranet" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>info</mat-icon>
              BC Intranet
              <div class="active-route-indicator"></div>
            </a>
            <!-- ADMIN -->
            <div mat-subheader>Admin</div>
            <a mat-list-item routerLink="/requests" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>question_answer</mat-icon>
              Anfragen
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/users" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>people</mat-icon>
              User
              <div class="active-route-indicator"></div>
            </a>
          </ng-container>
          <!-- TRAINER MENU -->
          <ng-container *ngIf="authSvc.isTrainer$ | async">
            <a mat-list-item [routerLink]="['/trainers/edit', trainerPath ]" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>directions_run</mat-icon>
              Mein Profil
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item [routerLink]="['/customers/list']" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>people</mat-icon>
              Meine Kunden
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/challenges" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>sports_score</mat-icon>
              Meine Challenges
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/exercises" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>fitness_center</mat-icon>
              Meine Exercises
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/nutrition-plans" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>list_alt</mat-icon>
              Meine Ernährungspläne
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/recipes" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>restaurant</mat-icon>
              Meine Rezepte
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/forms" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>assignment</mat-icon>
              Meine Formulare
              <div class="active-route-indicator"></div>
            </a>
            <a mat-list-item routerLink="/trainers/intranet" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>info</mat-icon>
              BC Intranet
              <div class="active-route-indicator"></div>
            </a>
          </ng-container>
          <!-- LOGOUT -->
          <ng-container *ngIf="authSvc.isLoggedIn$ | async">
            <mat-divider></mat-divider>
            <a mat-list-item href="#" (click)="onLogout()" [routerLinkActive]="['active-route']">
              <mat-icon mat-list-icon>logout</mat-icon>
              Logout
              <div class="active-route-indicator"></div>
            </a>
          </ng-container>
        </mat-nav-list>
      </div>
      <!-- AVATAR -->
      <div style="flex-shrink: 0;">
        <a [routerLink]="['/users/edit', (userSvc.currentUser$ | async)?.ref?.path ]" class="unstyled">
          <div style="display: flex; padding: 24px;">
            <img class="avatar mat-elevation-z8" [src]="(userSvc.currentUser$ | async)?.profileImageRef | downloadURL" />
            <div style="text-align: center;">
              <h4 class="user-name">
                {{ userSvc.currentUser$ | async | user:'fullName' }}
              </h4>
              <p class="role">{{ userSvc.currentUser$ | async | user:'role' }}</p>
            </div>
          </div>
        </a>
        <div style="background: #142439; text-align: center; padding: 12px;">
          2.8.3
        </div>
      </div>
    </div>
  </mat-sidenav>
  <!-- CONTENT -->
  <mat-sidenav-content>
    <mat-toolbar *ngIf="(authSvc.isLoggedIn$ | async) && (isHandset$ | async)" color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div style="padding: 10px 0; height: 100%;">
        <img src="/assets/img/bodycontrol_logo_white.png" style="max-height: 100%;" />
      </div>
    </mat-toolbar>
    <div class="container">
      <!-- APP ROOT -->
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>