import { Injectable, Injector } from '@angular/core';
import { Customer } from '../../shared/models/customer';
import { FirestoreService } from './firestore.service';
import { map } from 'rxjs/operators';
import { convertSnaps } from '../utils/db-utils';
import { DocumentReference } from '@angular/fire/firestore';
import { collectionSnapshots, collection, query, where, DocumentData } from '@angular/fire/firestore';

export const CUSTOMERS_PATH: string = 'customers';

@Injectable({
	providedIn: 'root'
})
export class CustomerService extends FirestoreService<Customer> {

	constructor(injector: Injector) {
		super(injector, CUSTOMERS_PATH);
	}

	getCustomersForTrainer(trainerRef: DocumentReference<DocumentData>) {
		const customerCollectionRef = collection(this.firestore, CUSTOMERS_PATH);
		return collectionSnapshots(query(customerCollectionRef, where('trainerRef', '==', trainerRef)))
			.pipe(
				map(snaps => convertSnaps<Customer>(snaps)),
				map(customers => customers.sort((c1, c2) => (c1.firstName > c2.firstName) ? 1 : ((c2.firstName > c1.firstName) ? -1 : 0)))
			);
	}

}
