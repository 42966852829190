import { Pipe, PipeTransform } from '@angular/core';
import { ExerciseFilterValues } from '../../modules/challenge/pages/challenge-edit/challenge-edit.component';
import { Exercise } from '../models/exercise';

@Pipe({
	name: 'filterExercises'
})
export class FilterExercisesPipe implements PipeTransform {

	transform(exercises: Exercise[] | null, exerciseFilterValues: ExerciseFilterValues | null): Exercise[] {
		const searchTerm = exerciseFilterValues?.searchTerm;
		const filteredMuscles = exerciseFilterValues?.muscles || [];
		const filteredGadgets = exerciseFilterValues?.gadgets || [];

		if (exercises) {
			return exercises.filter(exercise => {
				return (!searchTerm || exercise.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
					&& (filteredMuscles.length === 0 || filteredMuscles.every(muscle => exercise.trainedMuscles?.includes(muscle)))
					&& (filteredGadgets.length === 0 || filteredGadgets.every(gadget => exercise.requiredGadgets?.includes(gadget)));
			});
		}
		return exercises || [];
	}

}
