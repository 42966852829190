import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timeSeconds'
})
export class TimeSecondsPipe implements PipeTransform {

	transform(value: number): string {
		let seconds = value % 60;
		let minutes = Math.floor(value / 60);

		let s = minutes + ':';
		if (seconds < 10) {
			s += '0'
		}
		s += seconds;

		return s;
	}

}
