import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
	selector: 'app-password-reset-dialog',
	templateUrl: './password-reset-dialog.component.html',
	styleUrls: ['./password-reset-dialog.component.scss']
})
export class PasswordResetDialogComponent implements OnInit {

	passwordResetForm = new UntypedFormGroup({
		email: new UntypedFormControl('', [Validators.required, Validators.email]),
	});
	isLoading: boolean = false;

	constructor(
		private authSvc: AuthService,
		private snackbarSvc: SnackbarService,
		private dialogRef: MatDialogRef<PasswordResetDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: { email: string }
	) {
		if (data.email) {
			this.passwordResetForm.patchValue(data);
		}
	}

	ngOnInit(): void { }

	onSubmit() {
		if (this.passwordResetForm.valid) {
			this.isLoading = true;
			const email: string = this.passwordResetForm.value.email;
			this.authSvc.resetEmail(email).then(() => {
				this.snackbarSvc.showSuccess('Passwort zurücksetzen E-Mail gesendet.');
				this.dialogRef.close();
			}).catch(err => {
				this.snackbarSvc.showError(err.message);
				this.isLoading = false;
			})
		}
	}

}
