import { Injector } from '@angular/core';
import { Firestore, DocumentData, DocumentReference, doc, collection, addDoc, docSnapshots, collectionSnapshots, query, orderBy, OrderByDirection, updateDoc, deleteDoc, setDoc } from '@angular/fire/firestore';
import { map } from 'rxjs';
import { convertSnap, convertSnaps } from '../utils/db-utils';

export abstract class FirestoreService<T> {

  protected readonly firestore: Firestore;

  constructor(
    injector: Injector,
    private path: string,
  ) {
    this.firestore = injector.get(Firestore);
  }

  getAll(orderByProperty?: keyof T, orderByDirection?: OrderByDirection, parentRef?: DocumentReference<DocumentData>) {
    const collectionRef = this.getCollection(parentRef);
    const queryRef = !orderByProperty ? collectionRef : query(collectionRef, orderBy(orderByProperty as string, orderByDirection));
    return collectionSnapshots(queryRef).pipe(
      map(snaps => convertSnaps<T>(snaps))
    );
  }

  getById(id: string, parentRef?: DocumentReference<DocumentData>) {
    const docRef = this.getRef(id, parentRef);
    return docSnapshots(docRef).pipe(
      map(snap => {
        if (!snap.exists()) {
          throw new Error('Document not found');
        }
        return convertSnap<T>(snap);
      })
    )
  }

  add(obj: Partial<T>, parentRef?: DocumentReference<DocumentData>) {
    const collectionRef = this.getCollection(parentRef);
    return addDoc(collectionRef, obj as DocumentData);
  };

  async set(path: string, obj: Partial<T>) {
    const ref = doc(this.firestore, path);
    await setDoc(ref, obj as DocumentData)
    return ref;
  }

  /* async set(collectionRef: CollectionReference<DocumentData>, id: string, obj: Partial<T>) {
    const ref = doc(collectionRef, id);
    await setDoc(ref, obj as DocumentData)
    return ref;
  } */

  update<T>(ref: DocumentReference<T>, obj: Partial<T>) {
    /* for (const [key, val] of Object.entries(obj)) {
      if (val === undefined) {
        obj[key as keyof T] = deleteField() as any;
      }
    } */
    return updateDoc(ref, obj as Partial<unknown>);
  }

  delete<T>(ref: DocumentReference<T>) {
    return deleteDoc(ref);;
  }

  /*
    Helper
  */
  getDoc(path: string) {
    const docRef = doc(this.firestore, path);
    return docSnapshots(docRef)
      .pipe(
        map(snap => {
          if (snap.exists()) {
            return convertSnap<T>(snap);
          }
          throw new Error('Document not found');
        })
      );
  }

  getRef(id: string, parentRef?: DocumentReference<DocumentData>) {
    return !parentRef ? doc(this.firestore, this.path, id) : doc(this.firestore, parentRef.path, this.path, id);
  }

  getRootRef<T>(ref: DocumentReference<DocumentData>): DocumentReference<T> {
    let currentRef = ref;
    while (currentRef.parent.parent) {
      currentRef = currentRef.parent.parent;
    }
    return currentRef as DocumentReference<T>;
  }

  getCollection(parentRef?: DocumentReference<DocumentData>) {
    return !parentRef ? collection(this.firestore, this.path) : collection(this.firestore, parentRef.path, this.path);
  }

  createLocalId() {
    const collectionRef = collection(this.firestore, this.path);
    return doc(collectionRef).id;
  }

}
