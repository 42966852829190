import { Pipe, PipeTransform } from '@angular/core';
import { Meal } from '../models/meal';

@Pipe({
  name: 'filterRecipes'
})
export class FilterRecipesPipe implements PipeTransform {

  transform(recipes: Meal[], searchTerm: string): Meal[] {
		searchTerm = searchTerm.toLowerCase();

		// Recipe or Ingredient contains SearchTerm
		function containsSearchTerm(meal: Meal): boolean {
			if (meal.recipe.name.toLowerCase().includes(searchTerm)) {
				return true;
			}
			return meal.recipe.ingredients.some(ingredient => ingredient.food.product_name?.toLowerCase().includes(searchTerm) || ingredient.food.product_name_de?.toLowerCase().includes(searchTerm));
		}

		// Find Max Ingredient Quantity
		function findMaxIngredientQuantity(meal: Meal): number {
			return meal.recipe.ingredients
				.filter(ingredient => ingredient.food.product_name?.toLowerCase().includes(searchTerm) || ingredient.food.product_name_de?.toLowerCase().includes(searchTerm))
				.reduce((maxQuantity, ingredient) => Math.max(maxQuantity, ingredient.quantity.value), 0);
		}

		const filteredRecipes = recipes.filter(containsSearchTerm);

    // Sorting the filtered recipes
    filteredRecipes.sort((a, b) => {
        const aContainsInName = a.recipe.name.toLowerCase().includes(searchTerm);
        const bContainsInName = b.recipe.name.toLowerCase().includes(searchTerm);

        if (aContainsInName && !bContainsInName) return -1;
        if (!aContainsInName && bContainsInName) return 1;

        // For recipes without the search term in their name, sort by max ingredient quantity
        return findMaxIngredientQuantity(b) - findMaxIngredientQuantity(a);
    });

    return filteredRecipes;			
	}

}

