import { AfterContentInit, Component, ContentChildren, Input } from '@angular/core';
import { CarouselItemComponent } from '../carousel-item/carousel-item.component';

@Component({
	selector: 'app-carousel-group',
	templateUrl: './carousel-group.component.html',
	styleUrls: ['./carousel-group.component.scss'],
})
export class CarouselGroupComponent implements AfterContentInit {

	private _selectedIndex: number = 0;
	@Input() set selectedIndex(index: number) {
		this._selectedIndex = index;
		this.updateStepComponents();
	}
	get selectedIndex() {
		return this._selectedIndex;
	}

	@ContentChildren(CarouselItemComponent) items?: CarouselItemComponent[];

	ngAfterContentInit(): void {
		this.updateStepComponents();
	}

	updateStepComponents() {
		this.items?.forEach((item, i) => item.visible = this.selectedIndex == i);
	}

	onContentChange(ev: MutationRecord[]) {
		this.updateStepComponents();
	}

}
