import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'icon'
})
export class IconPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		switch (value) {
			case 'image':
				return 'image';
			case 'pdf':
				return 'picture_as_pdf';
			case 'text':
				return 'article';
			case 'video':
				return 'movie';
			default:
				return 'question_mark';
		}
	}

}
