import { DocumentData, DocumentReference, Timestamp } from "@angular/fire/firestore";
import { FirestoreDocument } from "./firestore-document";

export interface FormElement extends FirestoreDocument {
  answeredAt?: Timestamp;
  answerText?: string;
  index: number;
  parentFormElementRef?: DocumentReference<DocumentData>;
  questionText: string;
  questionTitle: string;
  type: FormElementType;
  options?: string[];
  selectedOptions?: number[];
}

export enum FormElementType {
  TEXT = 'text',
  NUMBER = 'number',
  SINGLE_CHOICE = 'single-choice',
  MULTIPLE_CHOICE = 'multiple-choice',
}

export const FORM_QUESITON_TYPE_VALUES = Object.values(FormElementType);