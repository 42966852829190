import { Pipe, PipeTransform } from '@angular/core';
import { ChallengeService } from 'src/app/core/services/challenge.service';
import { Customer } from '../models/customer';
import { Observable } from 'rxjs';

@Pipe({
	name: 'challengeCount'
})
export class ChallengeCountPipe implements PipeTransform {

	constructor(private challengeSvc: ChallengeService) { }

	transform(customer: Customer, challengeType: 'scheduled' | 'additional' = 'scheduled'): Observable<number | null> {
		if (challengeType === 'scheduled') {
			return this.challengeSvc.getRemainingChallengeDays(customer.ref);
		} else {
			return this.challengeSvc.getAdditionalChallengeCount(customer.ref);
		}
	}

}
